<template>
  <div class="container my-5">
    <div class="max-w-screen-sm mx-auto border border-solid border-gray-200 rounded-lg p-4">
      <div v-if="onePay" class="space-y-3">
        <div
          v-if="onePay.vpc_TxnResponseCode === '0'"
          class="text-green-600 font-bold text-xl text-center uppercase"
        >Giao dịch thành công</div>
        <div v-else>
          <p class="text-red-600 font-bold text-xl text-center uppercase mb-2">Giao dịch thất bại</p>
          <p
            class="font-bold text-center text-base mb-1"
          >{{ responseCode(onePay.vpc_TxnResponseCode) }}</p>
        </div>
        <div
          class="flex justify-between items-center border-b border-solid border-gray-200 border-t-0 border-r-0 border-l-0 pb-2"
        >
          <label class="font-bold">Người mua:</label>
          <!--<span>{{onePay.user_id}}</span>-->
          <span>{{ $user.name }}</span>
        </div>
        <!-- <div
          class="flex justify-between items-center border-b border-solid border-gray-200 border-t-0 border-r-0 border-l-0 pb-2"
        >
          <label class="font-bold">Giá tiền:</label>
          <span>{{getAmount(onePay.vpc_Amount)}}</span>
        </div>-->
        <div
          class="flex justify-between items-center border-b border-solid border-gray-200 border-t-0 border-r-0 border-l-0 pb-2"
        >
          <label class="font-bold">Số tài khoản:</label>
          <span>{{onePay.vpc_CardNum}}</span>
        </div>
        <div
          class="flex justify-between items-center border-b border-solid border-gray-200 border-t-0 border-r-0 border-l-0 pb-2"
        >
          <label class="font-bold">Mã giao dịch:</label>
          <span>{{onePay.vpc_MerchTxnRef}}</span>
        </div>
        <div
          class="flex justify-between items-center border-b border-solid border-gray-200 border-t-0 border-r-0 border-l-0 pb-2"
        >
          <label class="font-bold">Nội dung hóa đơn:</label>
          <span>{{onePay.vpc_OrderInfo}}</span>
        </div>
      </div>
      <div v-else>Chưa thực hiện giao dịch</div>
    </div>

    <div class="flex justify-center items-center">
      <button
        class="border-1 border-solid border-pri-900 bg-blue-50 text-pri-900 hover:bg-pri-900 hover:text-white transition-all duration-400 text-center px-3 py-2 m-3 rounded-lg"
        @click="goToEcommerce"
      >Tiếp tục mua sắm</button>
      <button
        v-if="onePay && onePay.vpc_TxnResponseCode === '0'"
        class="border-1 border-solid border-pri-700 bg-pri text-white hover:bg-pri-900 transition-all duration-400 text-center px-3 py-2 m-3 rounded-lg"
        @click="goToViewInvoice"
      >Xem trạng thái đơn hàng</button>
      <button
        v-else-if="onePay && onePay.vpc_TxnResponseCode !== '0' && (orderData && orderData.status !== 0)"
        class="btn bg-pri text-white px-3 py-2 m-3"
        @click="generateUrl"
      >Thanh toán lại</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { addHashQueryParam } from '@/utils/url'
import { ORDERS_STATUS } from '../../utils/constant'
import appUtils from '../../utils/appUtils'

export default {
  name: 'PaymentOrderEcom',
  data () {
    return {
      orderId: this.$route.params.id,
      onePay: null,
      orderData: null
    }
  },
  computed: {
    isResult () {
      return !!this.$route.query.hodo_result
    }
  },
  async created () {
    await this.getOrder()
  },
  beforeMount () {
    if (!this.isResult) {
      this.generateUrl()
    } else if (this.$route.query.vpc_MerchTxnRef) {
      this.getTransaction(this.$route.query.vpc_MerchTxnRef)
    }
  },
  methods: {
    responseCode (code) {
      if (code === '1') {
        return 'Ngân hàng từ chối cấp phép giao dịch.'
      }
      if (code === '2') {
        return 'Ngân hàng phát hành thẻ từ chối cấp phép giao dịch.'
      }
      if (code === '3') {
        return 'Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.'
      }
      if (code === '4') {
        return 'Tháng/Năm hết hạn của thẻ không đúng hoặc thẻ đã hết hạn sử dụng.'
      }
      if (code === '5') {
        return 'Số dư/Hạn mức của thẻ không đủ để thanh toán.'
      }
      if (code === '6') {
        return 'Không nhận được kết quả phản hồi từ Tổ chức phát hành thẻ.'
      }
      if (code === '7') {
        return 'Lỗi trong quá trình xử lý giao dịch của Ngân hàng.'
      }
      if (code === '8') {
        return 'Ngân hàng phát hành thẻ không hỗ trợ thanh toán trực tuyến.'
      }
      if (code === '9') {
        return 'Tên chủ thẻ/tài khoản không hợp lệ.'
      }
      if (code === '10') {
        return 'Thẻ hết hạn/Thẻ bị khóa.'
      }
      if (code === '11') {
        return 'Thẻ/Tài khoản chưa đăng ký dịch vụ hỗ trợ thanh toán trực tuyến.'
      }
      if (code === '12') {
        return 'Tháng/Năm phát hành hoặc hết hạn của thẻ không hợp lệ.'
      }
      if (code === '13') {
        return 'Giao dịch vượt quá hạn mức thanh toán trực tuyến theo quy định của Ngân hàng.'
      }
      if (code === '14') {
        return 'Số thẻ không hợp lệ. '
      }
      if (code === '21') {
        return 'Số dư tài khoản không đủ để thanh toán.'
      }
      if (code === '22') {
        return 'Thông tin tài khoản không hợp lệ.'
      }
      if (code === '23') {
        return 'Thẻ/Tài khoản bị khóa hoặc chưa được kích hoạt.'
      }
      if (code === '24') {
        return 'Thông tin thẻ/tài khoản không hợp lệ.'
      }
      if (code === '25') {
        return 'Mã xác thực OTP không hợp lệ.'
      }
      if (code === '26') {
        return 'Mã xác thực OTP đã hết hiệu lực.'
      }
      if (code === '98') {
        return 'Xác thực giao dịch bị hủy.'
      }
      if (code === '99') {
        return 'Người dùng hủy giao dịch.'
      }
      if (code === 'B') {
        return 'Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.'
      }
      if (code === 'D') {
        return 'Lỗi trong quá trình xác thực giao dịch của Ngân hàng phát hành thẻ.'
      }
      if (code === 'F') {
        return 'Xác thực giao dịch không thành công.'
      }
      if (code === 'U') {
        return 'Xác thực mã CSC không thành công.'
      }
      if (code === 'Z') {
        return 'Giao dịch bị từ chối.'
      }
      if (code === '253') {
        return 'Hết thời hạn nhập thông tin thanh toán.'
      }
      if (code === '') {
        return 'Lỗi không xác định.'
      }
    },
    async generateUrl () {
      const href = location.origin + '/#' + this.$route.path
      const res = await axios({
        url: 'https://staging.hodo.com.vn/api/node/onepay/generate-url',
        method: 'POST',
        data: {
          orderType: 1,
          orderId: parseInt(this.orderId),
          returnUrl: addHashQueryParam(href, 'hodo_result', '1')
        }
      })
      location.replace(res.data.url)
      console.log('res', res.data)
    },
    async getTransaction (txnId) {
      const res = await axios({
        url: `https://staging.hodo.com.vn/api/node/onepay/${txnId}`,
        method: 'GET'
      })
      console.log('res', res)
      if (res.data?.vpc_TxnResponseCode === '0') {
        window.$toast.open({
          message: 'Giao dịch thành công',
          type: 'success'
        })
      } else {
        window.$toast.open({
          message: 'Giao dịch thất bại',
          type: 'error'
        })
      }
      this.onePay = res.data

      const invoices = appUtils.getInvoices(this.$user.id)
      const invoice = invoices?.find(
        item => Number(item.order_id) === Number(this.orderId)
      )

      invoice.status =
        this.onePay && this.onePay.vpc_TxnResponseCode === '0'
          ? ORDERS_STATUS.RECEIVE_ORDERS
          : ORDERS_STATUS.ERROR_PAYMENT

      appUtils.setInvoices(invoices, this.$user.id)
      appUtils.removeAllCartData()

      // this.$message({
      //   type: "success",
      //   message: "Bạn đã đặt hàng thành công"
      // });
    },
    getAmount (amount) {
      return amount && this.numberFormat(Number(amount.slice(0, -2)))
    },
    numberFormat (number) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0 })
    },
    goToEcommerce () {
      this.$router.replace({ name: 'Ecommerce' }).catch(_ => {})
    },
    goToViewInvoice () {
      this.$router
        .replace({
          name: 'ProductOrderInvoice',
          params: {
            id: this.orderId
          }
        })
        .catch(_ => {})
    },
    async getOrder () {
      if (!Number(this.orderId)) return

      const res = await this.$rf
        .getRequest('AuthRequest')
        .showPackageOrder(Number(this.orderId))

      if (res.data) {
        this.orderData = res.data
      }
    },
    async cancelOrder () {
      if (
        this.onePay &&
        this.onePay.vpc_TxnResponseCode !== '0' &&
        this.orderData?.status !== 0
      ) {
        await this.$rf
          .getRequest('AuthRequest')
          .cancelPackageOrder(Number(this.orderId))
      }
    }
  },
  beforeDestroy () {
    this.cancelOrder()
  }
}
</script>
